// implement after creating page templates

import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './src/utils/linkResolver'
import PageTemplate from './src/pages/preview'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        // componentResolver: componentResolverFromMap({
        //   page: PageTemplate,
        // }),
        componentResolver: {
          page: PageTemplate,
          //page: lazy(() => import(PageTemplate)),
       },
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)