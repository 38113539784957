// Required for Prismic 
// Remember that the routes are available in the url field, not the uid field in the query

exports.linkResolver = (doc) => {
  //console.log(doc)  
    if (doc.type === 'homepage') {
      return `/`
    }

    if (doc.type === 'page') {
      return `/${doc.uid}`
    }
    
   return '/'
  }
  